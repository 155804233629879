import config from "./config.js"
import router from '../router'
    
export default {
	/**
	 * 显示文件
	 * @param {String} path 服务器文件路径
	 * @return {String} 完整可访问的路径
	 * */
	showFile(path) {
		if (!path) return path
		if (path.indexOf('http://') === -1 && path.indexOf('https://') === -1 && path.indexOf('blob:') === -1) {
			//服务器文件
			return config.fileServices + path
		} else {
			// 本地文件
			return path
		}
	},
	/**
	 * 根据标签id获取标签名
	 * @param {String} tagId  标签id字符串：1,2,3
	 * @return {Array} 标签名数组
	 * */
	getTagName(tagId) {
		tagId = tagId || ''
		let ary = []
		let allTag = JSON.parse(window.localStorage.getItem('allTag'))
		tagId = tagId.split(',')
		tagId.forEach(id => {
			for (var i = 0; i < allTag.length; i++) {
				let tag = allTag[i]
				if (Number(id) === Number(tag.id)) {
					ary.push(tag.tagName)
					break
				}
			}
		})
		return ary
	},
	/**
	 * 日期格式化
	 * @param {Number} timestamp 时间戳
	 * @param {String} symbol 连接符
	 * @param {String} format 格式 date-年月日 month-年月 time-日期和时间
	 * */
	dateFormat(timestamp, symbol, format) {
		let date = new Date(timestamp);
		let year = date.getFullYear(); // 年
		let month = date.getMonth() + 1; // 月
		let day = date.getDate(); // 日
		let hour = date.getHours(); // 时
		let minutes = date.getMinutes(); // 分
		let seconds = date.getSeconds() //秒
		// 给一位数的数据前面加 “0”
		if (month >= 1 && month <= 9) {
			month = "0" + month;
		}
		if (day >= 0 && day <= 9) {
			day = "0" + day;
		}
		if (hour >= 0 && hour <= 9) {
			hour = "0" + hour;
		}
		if (minutes >= 0 && minutes <= 9) {
			minutes = "0" + minutes;
		}
		if (seconds >= 0 && seconds <= 9) {
			seconds = "0" + seconds;
		}
		let str = ''
		if (symbol) {
			str = year + symbol + month + symbol + day
		}
		if (format === 'time') {
			str += ' ' + hour + ':' + minutes + ':' + seconds
		}
		if (format === 'month') {
			str = year + symbol + month + symbol
		}
		if (!symbol && !format) {
			str = year + '年' + month + '月' + day + '日' + ' ' + hour + ':' + minutes + ':' + seconds
		}
		// return year + '年' + month + '月' + day + '日' + ' ' + hour + ':' + minutes + ':' + seconds
		return str
	},
	// 改变rem单位大小
	Rem() {
		// 表示1920的设计图,使用100PX的默认值
		var whdef = 100 / 1920;
		// 当前窗口的宽度
		var bodyWidth = document.body.clientWidth;
		// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
		var rem = bodyWidth * whdef;
		if (bodyWidth > 1920) {
			rem = 100
		}
		document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
	},
	/**
	 * 将秒数转化为时分秒
	 * @param {Number} value 秒数
	 * */
	formatSeconds(seconds) {
		seconds = parseInt(seconds);
		var obj = {};
		obj.H = Number.parseInt(seconds / 3600);
		obj.i = Number.parseInt((seconds - obj.H * 3600) / 60);
		obj.s = Number.parseInt(seconds - obj.H * 3600 - obj.i * 60);
		if (obj.H < 10) {
			obj.H = '0' + obj.H;
		}
		if (obj.i < 10) {
			obj.i = '0' + obj.i;
		}
		if (obj.s < 10) {
			obj.s = '0' + obj.s;
		}

		return obj.H + ':' + obj.i + ':' + obj.s;
	},
	/**
	 * 查看产品详情
	 * @param {Object} item 产品信息
	 * @param {Number} pageType 页面类型，用于判断导航栏选中哪一个tab
	 * */
	viewProductDetails(item, pageType) {
		pageType
		if (JSON.stringify(item) === '{}') return;
		let id = item.pid ? item.pid : item.id
		// let dataForm = {
		// 	'title': item.title,
		// 	'description': item.description,
		// 	'id': item.pid ? item.pid : item.id, 
		// 	'ownerInfo': {
		// 		'avatarurl': item.ownerAvatarurl,
		// 		'nickname': item.ownerNickname,
		// 		'signature': item.signature
		// 	}
		// }
		if (item.releaseType === 0) {
			// 企划
			let routeUrl = router.resolve({
				path: "/product/details/planning/" + id,
				// query:{
				// 	dataForm:JSON.stringify(dataForm)
				// }
			});
			window.open(routeUrl.href, '_blank');
		} else if (item.releaseType === 1) {
			// 作品
			let routeUrl = router.resolve({
				path: "/product/details/work/" + id
			});
			window.open(routeUrl.href, '_blank');
		} else if (item.releaseType === 2) {
			// 风格
			let routeUrl = router.resolve({
				path: "/product/details/style/" + id,
				// query:{
				// 	dataForm:JSON.stringify(dataForm)
				// }
			});
			window.open(routeUrl.href, '_blank');
		} else if (item.releaseType === 3) {
			// 视频（发布）
			let routeUrl = router.resolve({
				path: "/product/details/video/" + id,
				// query:{
				// 	dataForm:JSON.stringify(dataForm)
				// }
			});
			window.open(routeUrl.href, '_blank');
		} else {
			// 活动
			let routeUrl = router.resolve({
				path: "/product/details/activity/" + id
			});
			window.open(routeUrl.href, '_blank');
		}
	}
}