import axios from '../utils/api'
import config from '../utils/config'

// 上传作品中的图片，视频
export function updataContent(data) {
  return axios({
    url: config.sysapi + '/Updata/Product/Content',
    method: 'POST',
    data: data
  })
}

// 上传封面
export function productCover(data) {
  return axios({
    url: config.sysapi + '/Updata/Product/cover',
    method: 'POST',
    data: data
  })
}

// 上传源文件
export function productCompress(data) {
  return axios({
    url: config.sysapi + '/Updata/Product/compress',
    method: 'POST',
    data: data
  })
}

// 获取某个分组的父子标签
export function getAllTagsByGroup(data) {
  return axios({
    url: '/tags/getAllTagsByGroup',
    method: 'GET',
    params: data
  })
}

// 发布产品
export function addProduct(data) {
  return axios({
    url: '/product/addProduct',
    method: 'POST',
    data: data
  })
}

// 查询我的作品列表
export function myWorksList(data) {
  return axios({
    url: '/product/myWorksList',
    method: 'POST',
    data: data
  })
}

// 查询所有标签
export function getAllTags() {
  return axios({
    url: '/tags/getAllTags',
    method: 'GET'
  })
}

// 分页查询产品列表
export function getProductPage(data) {
  return axios({
    url: '/product/getProductHomePage',
    method: 'POST',
    data: data
  })
}

// 查询所有创作者
export function getProductListOwner(data) {
  return axios({
    url: '/product/getProductListOwner',
    method: 'POST',
    data: data
  })
}

// 获取产品详情
export function getByPid(data) {
  return axios({
    url: '/product/getByPid',
    method: 'GET',
    params: data
  })
}

// 编辑产品
export function editProduct(data) {
  return axios({
    url: '/product/editProduct',
    method: 'POST',
    data: data
  })
}

// 点赞
export function like(data) {
  return axios({
    url: '/like/like',
    method: 'POST',
    data: data
  })
}

// 取消点赞
export function likeCancel(data) {
  return axios({
    url: '/like/likeCancel',
    method: 'POST',
    data: data
  })
}

// 收藏
export function collection(data) {
  return axios({
    url: '/collection/collection',
    method: 'POST',
    data: data
  })
}

// 取消收藏
export function collectionCancel(data) {
  return axios({
    url: '/collection/collectionCancel',
    method: 'POST',
    data: data
  })
}

// 获取源文件信息
export function getSourceFile(data) {
  return axios({
    url: '/product/getSourceFile',
    method: 'GET',
    params: data
  })
}

// 获取广告位内容
export function pageQueryAdPage(data) {
  return axios({
    url: '/ad/pageQueryAdPage',
    method: 'POST',
    data: data
  })
}

// 模糊搜索系统标签
export function getAllByTagNameLike(data) {
  return axios({
    url: '/tags/getAllByTagNameLike',
    method: 'GET',
    params: data
  })
}

// 提交授权及定制服务内容
export function submitInfo(data) {
  return axios({
    url: '/product/submitInfo',
    method: 'POST',
    data: data
  })
}

// 异步校验图片/音频
export function mediaCheckAsync(data) {
  return axios({
    url: config.sysapi + '/secCheck/mediaCheckAsync',
    method: 'POST',
    data: data
  })
}

//创建充值订单
export function creationCashInNative(data) {
  return axios({
    url: '/trad/creationCashInNative',
    method: 'POST',
    data: data
  })
}


//根据业务订单号查询充值订单详情
export function getRechargeOrder(data) {
  return axios({
    url: '/trad/getRechargeOrder',
    method: 'GET',
    params: data
  })
}

//购买会员订阅
export function buyMember(data) {
  return axios({
    url: '/trad/buyMember?sellUserid='+data.sellUserid+ '&membersType=' +data.membersType,
    method: 'POST',
    // data: data
  })
}

//查看会员有效期
export function getBySubscriber(data) {
  return axios({
    url: '/subscribe/getBySubscriber',
    method: 'GET',
    params: data
  })
}
