// 环境解析
const environmentalAnalysis = function () {
  // const origin = window.location.origin

  // 本地调试
  return {
    baseURL: 'https://api.mars-lab.cn'
  }
}

export default {
  url: environmentalAnalysis().baseURL, // 服务器api地址
  version: '1.0.0', //版本号
  appletsLoginQRcode: 'https://www.mars-lab.cn/pclogin', // 微信小程序授权登录二维码规则
  appletsUserInfo: 'https://www.mars-lab.cn/userInfo', // 微信小程序查看用户资料二维码规则
  sysapi: "https://sysapi.mars-lab.cn",// 公共服务api域名
  fileServices: "https://image.mars-lab.cn/", // 文件访问服务域名
  videoPathCaptions: "https://www.mars-lab.cn/product/video/track/", // 文件访问服务域名
  tagGroupsKey: {
    '0': 'planning', // 品牌
    '1': 'works',
    '2': 'style',
    '3': 'video', // 视频
    '4': 'patten', //图案
    '5': 'celeb', //明星
    '7': 'book', //书籍  
    '9': 'brand', // 品牌
    '10': 'runway2', // 秀场
    '11': 'market', // 市场
  }, // 各类型的产品对应标签分组key
  tagGroupsKeyName: {
    'planning': '趋势', // 企划
    'works': '作品', // 作品
    'style': '图库', // 风格日记
    'video': '视频', // 视频
  }, // 各产品标签分组key对应名称
  adPlaceKeyMap: {
    'home_head': 'pc_home_head', // 首页头部广告位
    'home_footer': 'pc_home_footer', // 首页底部广告位
    'filter': 'pc_filter', // 筛选条件弹框内广告位
    'user_dialog': 'pc_user_dialog' // 当前登录用户弹框内广告位
  } // 广告位对应key
}
