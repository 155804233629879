import Vue from 'vue'
import Vuex from 'vuex'
import {
  getUserByToken
} from '@/api/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    editData: {}, // 要编辑的产品或活动数据
    userInfo: {} // 当前登录用户信息
  },
  getters: {
  },
  mutations: {
    //保存editData
    setEditData(state, data) {
      state.editData = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    // 获取当前登录用户信息
    getUserByToken(context) {
      if(window.localStorage.getItem('token')){
        return getUserByToken().then(res => {
          if (res.code === 0) {
            context.commit('setUserInfo', res.data)
          }
        })
      } 
    },
  },
  modules: {
  }
})
