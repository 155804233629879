<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { getAllTags } from './api/product'
import publicFnc from './utils/public'
// function Rem() {
//   // 表示1920的设计图,使用100PX的默认值
//   var whdef = 100 / 1920;
//   // 当前窗口的宽度
//   var bodyWidth = document.body.clientWidth;
//   // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
//   var rem = bodyWidth * whdef;
//   if (bodyWidth > 1920) {
//     rem = 100
//   }
//   document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
// }
window.addEventListener('load', publicFnc.Rem);
window.addEventListener('resize', publicFnc.Rem)
export default {
  mounted() {
    publicFnc.Rem()
    this.getAllTags()
  },
  methods: {
    // 获取所有标签
    getAllTags() {
      getAllTags().then(res => {
        if (res.code === 0) {
          window.localStorage.setItem('allTag', JSON.stringify(res.data))
        }
      })
    }
  }
}
</script>
<style lang="less">
</style>
