import axios from 'axios'
import config from '@/utils/config'
import router from '../router'
import {Notification} from 'element-ui';
import { Message } from 'element-ui';

// 创建axios实例
const service = axios.create({
  baseURL: config.url, // 固定的请求地址
  timeout: 1000 * 60 * 5 // 请求超时时间
})
//允许携带cookie
axios.defaults.withCredentials = true;
// request拦截器
service.interceptors.request.use(config => {
  config.headers['token'] = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : '';
  return config
}, error => {
  // Do something with request error
  console.error(error) // for debug
  Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
  response => {
    switch (response.data.code) {
      case 0:
        break;
      case 400:
        // 小程序暂未授权登录，在这里不做处理
        break;
      case 1000:
        // 产品内容无权限查看
        break;
      default:
        Message.error(response.data.desc);
    }
    return response.data
  },
  error => {
    // alert('请求超时')
    if (error.message.indexOf('timeout') > -1) {
      Notification.error({
        title: '请求超时',
        message: error.message
      });
    } else {
      let message = error.response.data.message
      if (message && message.indexOf('code:"400"') > -1 && message.indexOf('用户未登录') > -1) {
        // 登录过期、未登录
        if (error.config.url !== '/users/getUserByToken') {
          router.push('/login')
        }
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('token')
      } else if (message) {
        Notification.error({
          title: '请求失败',
          message: message
        });
      } else {
        Notification.error({
          title: '请求失败',
          message: error.message
        });
      }
    }
    console.error(error); // for debug
    return Promise.reject(error)
  }
)


export default service
